export const Settings = {
  environment: 'production', // 'development' or 'production'
  company: 'demo', //vervangen door relevante company name
  URLs: {
    client: 'https://demo.receptuur.jbmsoftware.nl',
    server: 'https://demo.backend.jbmsoftware.nl', //vervangen door relevante server url
    planningApp: {
      root: 'https://demo.jbmsoftware.nl', // vervangen voor relevante url naar management
      recipeApp: {
        root: 'https://demo.backend.jbmsoftware.nl/recipe-app/', // vervangen door url naar server + /recipe-app/
        loginSegment: 'post/login'
      }
    },
    labApp: 'https://demo.lab.jbmsoftware.nl',
    orderApp: 'https://demo.jbmsoftware.nl',
    appLogin:'https://demo.backend.jbmsoftware.nl/recipe-app/post/login'
  },
  default_plant_id: 1//default plant moet in meeste gevallen gewoon 1 zijn
}
